import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import gql from "graphql-tag"
import $ from "jquery"
import React, { useEffect, useRef, useState } from "react"
import { Mutation } from "react-apollo"
import validator from "validator"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ServicesPage = () => {
  const pageQuery = useStaticQuery(graphql`
    query servicesQuery {
      allWpService(sort: { fields: date, order: ASC }) {
        nodes {
          id
          uri
          title
          content
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              sourceUrl
              sizes
            }
          }
          categories {
            nodes {
              uri
              name
              id
            }
          }
          services {
            attachments {
              sourceUrl
              mediaItemUrl
            }
          }
        }
      }
      allWpPage(filter: { uri: { eq: "/services/" } }) {
        nodes {
          uri
          title
          content
          seo {
            metaDesc
            title
          }
        }
      }
    }
  `)
  const services = pageQuery.allWpService.nodes
  const pageContent = pageQuery.allWpPage.nodes

  const DOWNLOAD_MUTATION = gql`
    mutation CreateDownloadMutation(
      $clientMutationId: String!
      $service: String!
      $firstName: String!
      $email: String!
    ) {
      createDownloadSubmission(
        input: {
          clientMutationId: $clientMutationId
          service: $service
          firstName: $firstName
          email: $email
        }
      ) {
        success
        data
      }
    }
  `

  useEffect(() => {
    $(".jobDownload").on("click", function (e) {
      e.preventDefault()
      let serviceName = $(this)
        .closest(".services__card")
        .find(".services__card_title")
        .text()
      $(".formDownload").data("href", $(this).data("href"))
      setServiceNameValue(serviceName)
      $(".job_popup, #popup_overlay").fadeIn(400).show()
      $("body").addClass("popup_active")
    })

    $("#popup_overlay, .job_popup > .job_popup__btn").on("click", function (e) {
      $(".job_popup, #popup_overlay").fadeOut(200)
    })

    $(".formDownload").on("click", function () {
      curDlUrl.current = $(this).data("href")
    })

    if (typeof window !== `undefined`) {
    }

    const servicesDownload = (name, email) => {
      setFirstNameValue(name)
      setEmailValue(email)

      if (validator.isEmail(emailValue)) {
        $(".formDownload").removeClass("disabledBtn")
      } else {
        $(".formDownload").addClass("disabledBtn")
      }
    }
  }, [])

  const initializeHubspotForm = () => {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "19906557",
        formId: "e221f63e-349f-46d4-9758-f1bb1505ec3a",
      })
    } else {
      setTimeout(initializeHubspotForm, 1000)
    }
  }

  const [serviceNameValue, setServiceNameValue] = useState("")
  const [firstNameValue, setFirstNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [errorValue, setErrorValue] = useState(null)
  const curDlUrl = useRef(null)

  return (
    <div>
      <Seo
        title={
          pageContent.seo?.title
            ? pageContent.seo.title
            : "Staffing Solutions in Medical Aesthetics"
        }
        description={pageContent.seo?.metaDesc}
      />

      <Layout>
        <section className="header_section">
          <div className="container">
            <div className="header_section__content">
              <h1>Services</h1>
              <div className="header_section__content_sub">
                <div>The Expert Recruiters in Medical Aesthetics </div>
              </div>
              <div className="header_section__content_btn text-center">
                <a href="#content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="bounce"
                    width="109.491"
                    height="57.574"
                    viewBox="0 0 109.491 57.574"
                  >
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M-847.3,2972.583h0l53.331,53.331-53.331,53.332"
                      transform="translate(3080.66 848.711) rotate(90)"
                      fill="none"
                      stroke="rgba(53,51,67,0.5)"
                      strokeWidth="4"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="header_section__image">
              <div className="header-container--standars">
                <StaticImage
                  src="../../static/assets/images/header/image_2.svg"
                  placeholder="blurred"
                  alt="Imgage 002"
                />
              </div>
            </div>
          </div>
          <div className="standard_header_path">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 1924.003 706.339"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M.5,1210.979h1924V504.64S1271.73,915.921,929.1,801.894.5,1037.8.5,1037.8Z"
                transform="translate(-0.5 -504.64)"
                fill="#ecebe4"
              />
            </svg>
          </div>
        </section>

        <section className="services">
          <div className="container" id="content">
            <div className="section__title text-center">
              Our Recruitment Services
            </div>
            <div className="services__holder">
              {services
                .filter(i => i.categories.nodes[0].id === "dGVybTo4NA==")
                .map(service => {
                  return (
                    <div
                     key={service.id}
                      className="services__card card"
                      id={"service" + service.id}
                    >
                      <div className="services__card_icon">
                        <img
                          src={service.featuredImage.node.sourceUrl}
                          alt={service.title}
                        />
                      </div>
                      <div className="services__card_title">
                        {service.title}
                      </div>
                      <div className="services__card_desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.content,
                          }}
                        />
                      </div>
                      {service.services.attachments && (
                        <a
                          className="btn jobDownload"
                          data-href={service.services.attachments.mediaItemUrl}
                        >
                          Download
                        </a>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        <section className="speak_to faq_cta">
          <div className="container">
            <div className="speak_to__box">
              <div className="speak_to__box_left">
                <div className="speak_to__box_title">
                  Frequently Asked Questions
                </div>
              </div>
              <div className="speak_to__box_right speak_to__box_right--right">
                <div className="speak_to__box_btn">
                  {" "}
                  <a className="btn" href="/faqs">
                    FAQs
                  </a>
                </div>
              </div>
              <div className="speak_to__box_icon">
                <img src="/assets/images/icons/question.svg" alt="question" />
              </div>
            </div>
          </div>
        </section>

        <section className="services">
          <div className="container">
            <div className="section__title text-center">Why choose ARC?</div>

            <div className="services__holder">
              {services
                .filter(i => i.categories.nodes[0].id === "dGVybTo4Nw==")
                .map(service => {
                  return (
                    <div key={service.id} className="services__card card">
                      <div className="services__card_icon">
                        <img
                          src={service.featuredImage.node.sourceUrl}
                          alt={service.title}
                        />
                      </div>
                      <div className="services__card_title">
                        {service.title}
                      </div>
                      <div className="services__card_desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.content,
                          }}
                        />
                      </div>
                      {service.services.attachments && (
                        <a className="btn" href={service.services.attachments}>
                          service.services.attachments.title
                        </a>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
        <div id="job_popup" className="job_popup job_popup__services">
          <div className="job_popup__icon">
            <img src="assets/images/icons/alert.svg" alt="alert" />
          </div>
          <div className="job_popup__title" style={{ marginBottom: "30px" }}>
            Before download begins
          </div>
          <Mutation mutation={DOWNLOAD_MUTATION}>
            {(createDownloadSubmission, { loading, error, data }) => (
              <React.Fragment>
                <form>
                  <h5
                    style={{
                      marginBottom: 20,
                      fontSize: 16,
                      color: "red",
                    }}
                  >
                    {errorValue}
                  </h5>
                  <input
                    type="hidden"
                    id="service"
                    className="service"
                    value={serviceNameValue}
                    style={{ opacity: 0 }}
                  />
                  <input
                    type="text"
                    placeholder="FIRST NAME"
                    id="first_name"
                    required
                    className="validate"
                    value={firstNameValue}
                    style={{ marginBottom: "20px" }}
                    onChange={firstName => {
                      setFirstNameValue(firstName.target.value)
                    }}
                  />
                  <input
                    type="email"
                    placeholder="EMAIL"
                    id="email"
                    required
                    className="validate"
                    value={emailValue}
                    style={{ marginBottom: "40px" }}
                    onChange={email => {
                      setEmailValue(email.target.value)
                    }}
                  />
                  <p>
                    ARC Aesthetic Professionals needs the contact information
                    you provide to us to contact you about our products and
                    services. You may unsubscribe from these communications at
                    any time. For information on how to unsubscribe, as well as
                    our privacy practices and commitment to protecting your
                    privacy, please review our Privacy Policy.
                  </p>

                  <div className="job_popup__btn">
                    <a
                      data-href=""
                      className="btn formDownload"
                      onClick={async event => {
                        event.preventDefault()
                        if (validator.isEmail(emailValue) && firstNameValue) {
                          createDownloadSubmission({
                            variables: {
                              clientMutationId: JSON.stringify(Date.now()),
                              service: serviceNameValue,
                              firstName: firstNameValue,
                              email: emailValue,
                            },
                          })

                          setErrorValue("")
                          window.location = curDlUrl.current
                        } else {
                          setErrorValue(
                            "Please enter your name and a valid email address."
                          )
                        }
                      }}
                    >
                      Download
                    </a>
                  </div>

                  <div
                    className="form_errors"
                    style={{
                      margin: "30px 0px 10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {loading && (
                      <p
                        style={{
                          margin: 10,
                          minWidth: "100%",
                          textAlign: "center",
                          fontSize: "1.3em",
                          fontWeight: 500,
                        }}
                      >
                        Sending...
                      </p>
                    )}
                    {error && (
                      <div
                        style={{
                          padding: "20px",
                          minWidth: "100%",
                          fontSize: "1.5rem",
                          fontWeight: 500,
                          backgroundColor: "#d06464",
                          color: "#eae9e1",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>
                          An unknown error has occured, please try again
                          later...
                        </p>
                      </div>
                    )}
                    {data && (
                      <div
                        style={{
                          padding: "20px",
                          minWidth: "100%",
                          fontSize: "1.5rem",
                          fontWeight: 500,
                          backgroundColor: "#98c2a4",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ margin: 0 }}>
                          Thank you! your download will begin shortly...
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </React.Fragment>
            )}
          </Mutation>
        </div>

        <div id="popup_overlay"></div>
      </Layout>
    </div>
  )
}

export default ServicesPage
